<!-- 订单信息 -->
<template>
  <div>
    <back />
    <div class="orderDetail" v-loading="loading">
      <h3 class="title">详情信息：</h3>
      <!-- 支付宝 -->
      <div v-if="detail.accountType == 1">
        <el-row class="row">
          <el-col :span="7">
            <div class="col">申请单号：{{ detail.applyNum }}</div>
            <div class="col">真实姓名：{{ detail.accountName }}</div>
            <div class="col">申请时间：{{ detail.createdTime }}</div>
            <div class="col" v-if="detail.status == 2" style="color: red;">不通过原因：{{ detail.auditMsg }}</div>
          </el-col>
          <el-col :span="7">
            <div class="col">供应商名称：{{ detail.applyUserName }}</div>
            <div class="col">账号：{{ detail.accountNum }}</div>
            <div class="col">审核时间：{{ detail.updatedTime }}</div>
          </el-col>
          <el-col :span="7">
            <div class="col">供应商编号：{{ detail.supplierCode }}</div>
            <div class="col">提现金额：{{ Number(detail.applyAmount).toFixed(2) }}元</div>
            <!-- 审核失败的时候不显示转账时间  状态：0待审核，1审核通过（已转账），2审核不通过，3转账失败-->
            <div class="col" v-if="detail.status == 1 || detail.status == 3">
              转账时间：{{ detail.receivedTime == null ? '-' : detail.receivedTime }}
            </div>
            <div class="col" v-if="detail.status == 2">
              状态：{{ stateTransformation(detail.status) }}
            </div>
          </el-col>
          <el-col :span="3">
            <div class="col">账户类型：{{ detail.accountType == 1 ? '支付宝' : '银行卡' }}</div>
            <div class="col">可提现金额：{{ Number(detail.availableAmount).toFixed(2) }}元</div>
            <div class="col" v-if="detail.status == 1 || detail.status == 3">
              状态：{{ stateTransformation(detail.status) }}</div>
          </el-col>
        </el-row>
      </div>
      <!-- 银行卡 -->
      <div v-else>
        <el-row class="row">
          <el-col :span="6">
            <div class="col">申请单号：{{ detail.applyNum }}</div>
            <div class="col">真实姓名：{{ detail.accountName }}</div>
            <div class="col">可提现金额：{{ Number(detail.availableAmount).toFixed(2) }}元</div>
            <div class="col" v-if="detail.status == 1 || detail.status == 3">状态：{{ stateTransformation(detail.status) }}
            </div>
            <div class="col" v-if="detail.status == 2" style="color: red;">不通过原因：{{ detail.auditMsg }}</div>
          </el-col>
          <el-col :span="6">
            <div class="col">供应商名称：{{ detail.applyUserName }}</div>
            <div class="col">账号：{{ detail.accountNum }}</div>
            <div class="col">申请时间：{{ detail.createdTime }}</div>
          </el-col>
          <el-col :span="6">
            <div class="col">供应商编号：{{ detail.supplierCode }}</div>
            <div class="col">开户行：{{ detail.opeBank }}</div>
            <div class="col">审核时间：{{ detail.updatedTime }}</div>
          </el-col>
          <el-col :span="6">
            <div class="col">账户类型：{{ detail.accountType == 1 ? '支付宝' : '银行卡' }}</div>
            <div class="col">提现金额：{{ Number(detail.applyAmount).toFixed(2) }}元</div>
            <div class="col" v-if="detail.status == 1 || detail.status == 3">
              转账时间：{{ detail.receivedTime == null ? '-' : detail.receivedTime }}
            </div>
            <div class="col" v-if="detail.status == 2">状态：{{ stateTransformation(detail.status) }}</div>
          </el-col>
        </el-row>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      detail: {},
      id: '',
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getRequestList()
  },
  methods: {
    getRequestList() {
      this.loading = true;
      const url = `${this.$api.setSupplierListDet}/${this.id}`
      this.$axios.get(url).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.detail = result
        }
        this.loading = false;
      })
        .catch(() => {
          this.loading = false;
        });
    },
    // 状态转换
    stateTransformation(status) {
      switch (String(status)) {
        case '1':
          return '已转账'
        case '2':
          return '审核失败'
        default:
          return '转账失败'
      }
    },
  }

}

</script>

<style lang="scss" scoped>
.orderDetail {
  padding: 20px;

  .title {
    margin-bottom: 25px;
  }

  .row {
    color: #707070;
  }

  .col {
    margin-bottom: 25px;
    margin-right: 10px;
  }

  // 实付金额
  .pay-fee {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    color: red;
  }
}
</style>